import React from "react";
import styled from "styled-components";
import "../../Routes/Home/homeContainer.css"; 

const PrivacyPolicyContainer = styled.div`
    background-color: #000; 
    color: white; 
    padding: 40px 20px; 
    border-radius: 10px; 
    margin: 20px; 
    max-width: 1200px; 
    margin: auto; 
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); 
`;

const Section = styled.section`
    margin-bottom: 30px; 
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
    font-size: 3rem;
`;

const Subtitle = styled.h2`
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.6rem;
`;

const Text = styled.p`
    line-height: 1.8; 
    font-size: 1.3rem;  
    margin-bottom: 15px;  
`;

const PrivacyPolicy = () => {
    return (
        <PrivacyPolicyContainer>
            <Title>Politique de Confidentialité</Title>
            <Text>
                Bienvenue à la politique de confidentialité de Nexgen IPTV.
            </Text>
            <Text>
                1. Cette politique définit la manière dont nous traitons vos informations personnelles si vous êtes notre utilisateur ou visiteur sur notre site. Elle s'applique à l'ensemble de notre réseau.
            </Text>
            <Text>
                2. Quand nous disons "nous", "nous" ou "Nexgen IPTV", c'est parce que c'est ce que nous sommes et que nous possédons et gérons le site.
            </Text>
            <Text>
                3. Si nous disons "politique", nous parlons de cette politique de protection de la vie privée. Si nous disons "conditions d'utilisation", nous parlons des règles d'utilisation de notre site. Les règles varient selon les produits et chaque produit les met à disposition séparément et demande le consentement à cette politique séparément.
            </Text>

            <Section>
                <Subtitle>Le type d'informations personnelles que nous recueillons</Subtitle>
                <Text>
                    1. Nous recueillons certaines informations personnelles sur les visiteurs et les utilisateurs de notre site.
                </Text>
                <Text>
                    2. Les types d'informations les plus courantes que nous recueillons sont les suivants : noms d'utilisateurs, noms de membres, adresses électroniques, adresses IP, autres coordonnées, réponses aux enquêtes, informations sur les paiements telles que les coordonnées des agents de paiement, détails des transactions, informations fiscales, demandes d'assistance, commentaires sur les forums, contenu que vous nous demandez de mettre à disposition sur notre site (comme les descriptions des articles) et données d'analyse web. Nous recueillons également des informations personnelles à partir des candidatures (telles que votre CV, le formulaire de candidature lui-même, la lettre de motivation et les notes d'entretien).
                </Text>
            </Section>

            <Section>
                <Subtitle>Comment nous recueillons des informations personnelles</Subtitle>
                <Text>
                    1. Nous recueillons des informations personnelles directement lorsque vous nous les fournissez, automatiquement lorsque vous naviguez sur le site, ou par l'intermédiaire d'autres personnes lorsque vous utilisez des services associés à notre site.
                </Text>
                <Text>
                    2. Nous recueillons vos informations personnelles lorsque vous nous fournissez lors de l'enregistrement de votre adhésion et lorsque vous activez ou fournissez des articles ou des services sur notre site, lorsque vous faites part de vos commentaires, lorsque vous participez à un concours, lorsque vous répondez à une enquête ou lorsque vous nous envoyez une communication.
                </Text>
            </Section>
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicy;
