import React, { useState, useEffect } from "react";
import styled from "styled-components";
import backgroundImage from "../assets/imgs/nexg-iptv-bg.webp"; // Import your background image

// Styled components
const Section = styled.div`
    position: relative;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 20px;

    .header-sec-content {
        max-width: 800px;
        margin: 0 auto;

        h1 {
            font-family: 'Poppins', sans-serif; /* Using Poppins for the title */
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
            color: #f0b429; /* Gold accent color for the title */
            
            span {
                color: #fff;
                background-color: #f0b429; /* Gold background for emphasis */
                padding: 0 10px;
                border-radius: 5px;
            }
        }

        p {
            font-family: 'Poppins', sans-serif; /* Same font for consistency */
            font-size: 22px;
            margin: 10px 0;
            color: #fff;

            span.warm {
                color: #f0b429; /* Gold accent for important words */
                font-weight: 600;
            }
        }
    }
`;

// Subscription button with sticky position and animations
const SubscriptionButton = styled.button`
    position: fixed;
    top: ${(props) => (props.scrolled ? "10px" : "6%")};
    right: 20px;
    background-color: ${(props) => (props.scrolled ? "#f0b429" : "#0a0f2c9e")};
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: ${(props) => (props.scrolled ? "1.2rem" : "1rem")};
    z-index: 10;
    transition: background-color 0.3s, font-size 0.3s, top 0.3s;
    
    &:hover {
        background-color: darkred;
    }

    // Pulse animation
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(255, 71, 71, 0.4);
        }
        70% {
            box-shadow: 0 0 0 15px rgba(255, 71, 71, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(255, 71, 71, 0);
        }
    }

    ${(props) => props.scrolled && `animation: pulse 2s infinite;`}
`;

// Main section component
const HomeFirstSection = () => {
    const [scrolled, setScrolled] = useState(false);

    // Listen to scroll events to trigger animation
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight * 0.3) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToPercentage = (percentage) => {
        const totalPageHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPosition = (totalPageHeight * percentage) / 100;
        window.scrollTo(0, scrollPosition);
    };

    return (
        <Section>
            <div className="header-sec-content">
                <h1 className="title">
                    VOTRE MEILLEUR IPTV <span>PREMIUM</span>
                </h1>
                <p>
                    Marre des <span className="warm">COUTS ÉLEVÉS</span> et des
                    chaînes limitées ?{" "}
                </p>
                <p>Essayez notre service IPTV dès maintenant</p>
            </div>
            <SubscriptionButton scrolled={scrolled} onClick={() => scrollToPercentage("48")}>
                Subscriptions ❯
            </SubscriptionButton>
            <div id="chewefrg" className="wpcf7">
                <div id="scroll-down-animation">
                    <span className="mouse">
                        <span className="move"></span>
                    </span>
                </div>
            </div>
        </Section>
    );
};

export default HomeFirstSection;
