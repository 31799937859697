import React from "react";
import styled, { keyframes } from "styled-components";
import "./homeContainer.css";
import "reactjs-popup/dist/index.css";
import QnA from "Components/QnA";
import HomeFirstSection from "Components/HomeFirstSection";
import Footer from "Components/Footer";
import PrincingTable from "Components/princingTable";
import WhatsAppChatButton from "Components/WhatsAppChatButton";
import worldMap from "../../assets/imgs/world-map.webp";
import allDevices from "../../assets/imgs/all-devices.webp"; 
import appDevices from "../../assets/imgs/app_devices.webp"; 
import GifImg from "../../assets/imgs/7994366.gif";

const HomeContainer = () => {

    const CustomCards = styled.div`
        width: 1040px;
        margin: 0 auto;

        @media (min-width: 600px) and (max-width: 991px) {
            width: 450px;
            margin: 0 auto;
        }
        @media (max-width: 600px) {
            width: 300px;
            margin: 0 auto;
        }
    `;

    const AllDevicesSection = styled.div`
        color: white;
        padding: 50px 0;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }

        h1 {
            font-size: 2.5rem;
        }

        @media (max-width: 768px) {
            h1 {
                font-size: 1.8rem;
            }
            p {
                font-size: 1rem;
            }
        }
    `;

    // Keyframe animation for the scrolling effect
    const scrollLeft = keyframes`
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    `;

    const DevicesWrapper = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        overflow: hidden;  /* Hide the overflowing content */
        white-space: nowrap; /* Prevent line breaks */

        img {
            max-width: 80%;
            height: auto;
        }

        @media (max-width: 768px) {
            img {
                max-width: 100%;
            }
        }
    `;

    const ScrollingWrapper = styled.div`
        display: inline-block;
        animation: ${scrollLeft} 20s linear infinite; /* Infinite scrolling animation */
    `;

	const WorldSection = styled.section`
		background-color: #0a0f2c; 
		color: white;
		padding: 80px 0; 
		text-align: center;

		h1 {
			margin-bottom: 40px; 
			font-size: 2rem;
			line-height: 1.5;
			padding: 0 20px; 
		}

		img {
			max-width: 80%; 
			height: auto;
			margin: 0 auto;
		}

		@media (max-width: 768px) {
			padding: 60px 0;
			h1 {
			font-size: 1.5rem;
			padding: 0 10px;
			}
			img {
			max-width: 100%;
			}
		}
		`;

    return (
        <React.Fragment>
            <HomeFirstSection />
   {/* Devices Section with scrolling animation */}
   <AllDevicesSection>
                <div className="container">
                    <h1>Profitez d'une Compatibilité Parfaite avec Tous Vos Appareils</h1>
                    <p>
                        Vivez une expérience de divertissement sans limites avec notre abonnement IPTV, 
                        conçu pour s'intégrer parfaitement à une large gamme d'appareils.
                    </p>
                    <DevicesWrapper>
                            <img src={allDevices} alt="All compatible devices" className="img-fluid" />
                    </DevicesWrapper>
                </div>
                <DevicesWrapper>
                    <ScrollingWrapper>
                        <img src={appDevices} alt="Devices and Apps" className="img-fluid my-4" />
                    </ScrollingWrapper>
                </DevicesWrapper>
            </AllDevicesSection>
            {/* Custom Cards */}
            <CustomCards className="container" id="custom-cards">
                <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
                    {/* Card 1 */}
                    <div className="col">
                        <div className="card overflow-hidden text-bg-white rounded-5 shadow-lg" style={{ height: "470px" }}>
                            <div className="h-100 p-5 pb-3 text-white">
                                <img
                                    src={GifImg}
                                    alt="Commander ici"
                                    className="video_box d-flex justify-content-center"
                                    loop="true"
                                />
                                <h3 className="mb-4 display-6 lh-1 fw-bold text-dark">
                                    Commander
                                </h3>
                                <p className="text-dark">
                                    Une fois la commande passée, vos liens et identifiants
                                    apparaîtront sur la page de remerciement et sur votre boite
                                    email
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col">
                        <div className="card overflow-hidden text-bg-dark rounded-5 shadow-lg">
                            <div className="h-100 p-5 pb-3 text-white">
                                <img
                                    alt=""
                                    className="icon_img"
                                    src="https://cdn-icons-png.flaticon.com/512/3289/3289278.png"
                                />
                                <h3 className="mt-5 mb-4 display-6 lh-1 fw-bold">Installer</h3>
                                <p>
                                    Suivez les instructions d'installation pour chaque appareil
                                    que vous souhaitez utilisez
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Card 3 */}
                    <div className="col">
                        <div className="card card-cover overflow-hidden rounded-5 shadow-lg">
                            <div className="h-100 p-5 pb-3">
                                <img
                                    alt=""
                                    className="icon_img"
                                    src="https://cdn-icons-png.flaticon.com/512/3028/3028390.png"
                                />
                                <h3 className="mt-5 mb-4 display-6 lh-1 fw-bold text-dark">Regarder</h3>
                                <p className="text-dark">
                                    Vivez votre passion sur votre télé, votre smartphone ou votre
                                    ordinateur
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomCards>

            <PrincingTable />
            

			<WorldSection>
				<h1 className="text-white">
				Accédez aux Meilleures Chaînes de Télévision et Contenus à la Demande en Qualité Ultra HD
				</h1>
				<img src={worldMap} alt="World Map" />
			</WorldSection>

            <QnA />
            <section className="centered">
                <div className="row">
                    <Footer />
                </div>
            </section>

            <WhatsAppChatButton />
        </React.Fragment>
    );
};

export default HomeContainer;
