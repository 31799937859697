import React, { useEffect } from "react";
import Router from "./Router";
import GlobalStyles from "./GlobalStyles";

function App() {
	useEffect(() => {
		console.clear();
		console.log(
			"%cSTOP !",
			"background: #333; color: red; padding: 5px; border-radius: 4px; font-weight: bold; font-size: 48px;"
		);
		console.error("This feature is intended for developers.");
	}, []);
	return (
		<React.Fragment>
			<GlobalStyles />
			<Router />
		</React.Fragment>
	);
}

export default App;
