import React, { useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import WhatsAppIconImage from '../assets/imgs/whatssap.webp';
import MessageIcon from '../assets/imgs/message-icon.webp'; 

const CloseIcon = styled.img`
  width: 20px;  /* Smaller size for the close icon */
  height: 20px;  /* Adjust this as per your need */
`;

const WhatsAppChatButton = () => {
  const [visible, setVisible] = useState(true); 
  const [expanded, setExpanded] = useState(false); 
  const [showPopup, setShowPopup] = useState(false); // For the chat popup

  useEffect(() => {
    // Check if the popup has already been closed before
    const isPopupClosed = localStorage.getItem('popupClosed');
    
    // If the popup has not been closed, show it initially
    if (!isPopupClosed) {
      setShowPopup(true);
    }

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.2) {
        setVisible(true);
      } else {
        setVisible(true); 
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleWhatsAppClick = () => {
    const whatsappApiUrl = `https://api.whatsapp.com/send?phone=224626599569`;
    window.open(whatsappApiUrl, '_blank');
  };

  // Handle expand button
  const handleExpand = () => {
    setExpanded(true);  
  };

  // Handle close button for the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem('popupClosed', 'true'); // Store the closed state in localStorage
  };

  // Handle close for the buttons
  const handleClose = () => {
    setExpanded(false);  
  };

  if (!visible) return null;

  return (
    <FloatingButtonContainer>
      {showPopup && (
        <ChatPopup>
          <PopupHeader>
            <span>Welcome to NexgGen IPTV</span>
            <CloseIcon src="https://cdn-icons-png.flaticon.com/512/1828/1828778.png" onClick={handleClosePopup} />
          </PopupHeader>
          <PopupBody>
            Feel free to chat with us if you have any questions or need assistance. We're here to help!
          </PopupBody>
        </ChatPopup>
      )}

      {/* Message button */}
      {!expanded && (
        <FloatingButton onClick={handleExpand}>
          <img src={MessageIcon} alt="Expand" />
        </FloatingButton>
      )}

      {/* Expanded buttons */}
      {expanded && (
        <>
          <FloatingButton bgColor="#25D366" delay="0.3s" onClick={handleWhatsAppClick}>
            <img src={WhatsAppIconImage} alt="WhatsApp Icon" />
          </FloatingButton>
          <FloatingButton bgColor="#e74c3c" delay="0.4s" onClick={() => window.location.href = 'mailto:support@nexgen-iptv.store'}>
            <img src="https://cdn-icons-png.flaticon.com/512/561/561127.png" alt="Email" />
          </FloatingButton>
          <FloatingCloseButton delay="0.5s" onClick={handleClose}>
            <img src="https://cdn-icons-png.flaticon.com/512/1828/1828778.png" alt="Close" />
          </FloatingCloseButton>
        </>
      )}
    </FloatingButtonContainer>
  );
};

// Styled components

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FloatingButtonContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;

  & > * {
    margin-bottom: 10px;
  }
`;

const FloatingCloseButton = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => props.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    opacity: 0;
    animation: ${fadeIn} 0.5s ease forwards;
    animation-delay: ${(props) => props.delay};  /* Different delay for each button */
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    }

    img {
      width: 24px;
      height: 24px;
    }
`;

const FloatingButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
  animation-delay: ${(props) => props.delay};  /* Different delay for each button */
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  img {
    width: 24px;
    height: 24px;
  }
`;

// Chat Popup Styling
const ChatPopup = styled.div`
  position: fixed;
  bottom: 80px; /* Position above the buttons */
  right: 20px;
  background-color: rgb(255 255 255 / 20%);
  color: black;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 1100;
  font-size: 14px;
  padding: 15px;
  animation: ${fadeIn} 0.5s ease forwards;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
`;

const PopupBody = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

export default WhatsAppChatButton;
