import React, { useState } from "react";
import logo from "../assets/imgs/logonexgenwebp.webp"
import { Link } from "react-router-dom"; // Import Link for navigation


const Header = () => {

	const [site1] = useState("/");
	 
	return (
		<React.Fragment>
					<div className="nav-brand-name">
					<Link to={site1} className="logo"> 
						<img src={logo} alt="logo" />
				</Link>
					</div>
		</React.Fragment>
	);
};

export default Header;
