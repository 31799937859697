import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "Routes/Home";
import Header from "./Header";
import PrivacyPolicy from "./PrivacyPolicy-page/PrivacyPolicy"
import TosPage from "./TermsAndConditions-page/TosPage";
// import AboutUs from "Components/AboutUs";
// import PrivacyPolicy from "./PrivacyPolicy"
// import TermsPage from "./TermsPage";
// import RefundPage from "./RefundPage";

const Router = () => {
	return (
		<BrowserRouter>
		<Header></Header>
			<Switch>
				<Route path="/" exact component={Home}></Route>
				{/* <Route path="/about-us" exact component={AboutUs}></Route> */}
				{/* {/* <Route path="/privacy-policy" exact component={"#"}></Route> */}
				<Route path="/terms-and-conditions" exact component={TosPage}></Route>
				<Route path="/privacy-policy" exact component={PrivacyPolicy}></Route>
				{/* <Route path="/refund-policy" exact component={"#"}></Route>  */}
			</Switch>
		</BrowserRouter>
	);
};

export default Router;
