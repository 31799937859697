import React from "react";
import styled from "styled-components";
import "../../Routes/Home/homeContainer.css"; 

const TosPageContainer = styled.div`
    background-color: #000; 
    color: white; 
    padding: 40px 20px; 
    border-radius: 10px; 
    margin: 20px; 
    max-width: 1200px; 
    margin: auto; 
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); 
`;

const Section = styled.section`
    margin-bottom: 30px; 
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
    font-size: 3rem;
`;

const Text = styled.p`
    line-height: 1.8; 
    font-size: 1.3rem;  
    margin-bottom: 15px;  
`;

const TermsList = styled.ol`
    margin-left: 20px; 
    font-size: 1.2rem; 
`;

const H4Title = styled.h4`
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #ff0000; // Customize color if needed
`;

const TosPage = () => {
    return (
        <TosPageContainer>
            <Title><strong>TERMES ET CONDITIONS</strong></Title>
            {/* Conditions d'utilisation Section */}
            <Section>
                <H4Title>Conditions d'utilisation</H4Title>
                <TermsList>
                    <Text>
                        L'utilisation des services de www.nexgen-iptv.store, [ci-après dénommé «NexGen IPTV»] constitue un accord sur ces conditions. Vous pouvez consulter notre politique de confidentialité ici.
                    </Text>
                </TermsList>
            </Section>

            {/* Configuration du compte / e-mail dans le fichier Section */}
            <Section>
                <H4Title>Configuration du compte / e-mail dans le fichier</H4Title>
                <TermsList>
                    <Text>
                        Nous configurerons votre compte après réception du paiement et nous et / ou notre (nos) partenaire (s) de paiement avons examiné la ou les commandes en cas de fraude. Il est de votre responsabilité de nous fournir une adresse e-mail qui n'est pas @ le (s) domaine (s) sous lequel vous vous inscrivez. En cas de problème d'abus ou si nous devons vous contacter, l'adresse e-mail principale enregistrée sera utilisée à cette fin. Il est de votre responsabilité de vous assurer que l'adresse e-mail enregistrée est à jour ou à jour à tout moment. Si vous avez un nom de domaine enregistré auprès de NexGen IPTV, il est de votre responsabilité de vous assurer que les informations de contact de votre compte de domaine et de votre ou vos noms de domaine réels sont correctes et à jour. NexGen IPTV n'est pas responsable d'une inscription caduque en raison d'informations de contact obsolètes associées au domaine. Si vous avez besoin de vérifier ou de modifier ces informations, vous devez nous contacter par e-mail. Fournir de fausses informations de contact de quelque nature que ce soit peut entraîner la résiliation de votre compte. Dans les achats de serveurs dédiés ou les transactions à haut risque, il sera nécessaire de fournir une pièce d'identité émise par le gouvernement et éventuellement un scan de la carte de crédit utilisée pour l'achat. Si vous ne remplissez pas ces conditions, la commande peut être considérée comme frauduleuse et être refusée.
                    </Text>
                </TermsList>
            </Section>

            {/* Transferts Section */}
            <Section>
                <H4Title>Transferts</H4Title>
                <TermsList>
                    <Text>
                        1. Notre équipe d'assistance mettra tout en œuvre pour vous aider à déplacer votre site chez nous. Cependant, les virements sont fournis à titre de service de courtoisie et nous ne pouvons pas garantir la disponibilité, la possibilité ou le temps nécessaire pour effectuer un virement de compte. Chaque société d'hébergement est configurée différemment et certaines plates-formes d'hébergement enregistrent les données dans un format incompatible ou propriétaire, ce qui peut rendre extrêmement difficile, voire impossible, la migration de certaines ou de toutes les données de compte. Nous ferons de notre mieux, mais dans de très rares cas, il se peut que nous ne puissions pas vous aider dans un transfert de données depuis un ancien hôte.
                    </Text>
                    <Text>
                        2. Les services de transfert gratuits sont disponibles pendant 30 jours à compter de votre date d'inscription et uniquement disponibles si votre société d'hébergement précédente utilise cPanel. Si votre société précédente exploitait un panneau de configuration non standard, des frais de migration pourraient être facturés.
                    </Text>
                </TermsList>
            </Section>

            {/* Contenu Section */}
            <Section>
                <H4Title>Contenu</H4Title>
                <TermsList>
                    <Text>
                        1. Tous les services fournis par NexGen IPTV ne peuvent être utilisés qu'à des fins légales. Les lois de l'État de Californie et des États-Unis d'Amérique s'appliquent.
                    </Text>
                    <Text>
                        2. Le client s'engage à indemniser et à dégager NexGen IPTV de toute réclamation résultant de l'utilisation de nos services.
                    </Text>
                    <Text>
                        3. L'utilisation de nos services pour enfreindre tout droit d'auteur ou marque de commerce est interdite. Cela inclut, mais sans s'y limiter, la copie non autorisée de musique, de livres, de photographies ou de tout autre travail protégé par le droit d'auteur. L'offre de vente de toute marchandise contrefaite d'un titulaire de marque entraînera la résiliation immédiate de votre compte. Tout compte jugé en violation avec un autre droit d'auteur sera rapidement supprimé ou l'accès au matériel sera désactivé. Tout compte reconnu en violation répétée des lois sur les droits d'auteur sera suspendu et / ou résilié de notre hébergement. Si vous pensez que votre droit d'auteur ou votre marque est enfreint, veuillez nous contacter avec une demande DMCA valide. Si la demande concerne un problème de licence, nous pouvons exiger des documents supplémentaires.
                    </Text>
                    <Text>
                        4. Des exemples de matériel inacceptable sur tous les plans d'hébergement comprennent:
                    </Text>
                    <TermsList>
                        <li>Scripts IRC / Bots</li>
                        <li>Proxy Scripts / Anonymizers</li>
                        <li>Pirated Software / Warez (Le lien vers un logiciel piraté n'est pas non plus autorisé.)</li>
                        <li>Sites AutoSurf / PTC / PTS / PPC ou script de serveur publicitaire / scripts de campagne de bannière</li>
                        <li>Scanners IP</li>
                        <li>Programmes Bruteforce / Scripts / Applications</li>
                        <li>Mail Bombers / Spam Scripts</li>
                        <li>File Dump / Mirror Scripts (similaire à Rapidshare)</li>
                        <li>Commercial Audio Streaming (plus d'un ou deux flux)</li>
                        <li>Escrow / Bank Debentures</li>
                        <li>Programmes d'intérêt à haut rendement (HYIP) ou sites associés</li>
                        <li>Sites de loterie / de jeu</li>
                        <li>MUD / RPG / PBBG</li>
                        <li>Sites / archives / programmes axés sur les hackers</li>
                        <li>Sites faisant la promotion d'activités illégales</li>
                        <li>Forums et / ou sites Web qui distribuent ou établissent des liens vers des warez / piratés / contenu illégal</li>
                        <li>Sites frauduleux (y compris, mais sans s'y limiter, les sites répertoriés sur aa419.org et escrow-fraud.com)</li>
                        <li>Mailer Pro</li>
                        <li>Diffusion ou diffusion d'événements sportifs en direct (UFC, NASCAR, FIFA, NFL, MLB, NBA, WWE, WWF, etc.)</li>
                    </TermsList>
                </TermsList>
            </Section>

            {/* Politique de tolérance zéro pour le spam Section */}
            <Section>
                <H4Title>Politique de tolérance zéro pour le spam</H4Title>
                <TermsList>
                    <Text>
                        1. Nous adoptons une position de tolérance zéro contre l'envoi de courriers électroniques non sollicités, les courriers électroniques en masse et les spams. Les «listes sécurisées» et les listes achetées seront traitées comme du spam. Tout utilisateur qui envoie du spam verra son compte résilié sans aucun remboursement et sans préavis. Même si le compte envoie du spam dans le premier mois suivant le début du service avec nous, le compte sera résilié sans remboursement.
                    </Text>
                    <Text>
                        2. Les sites annoncés via SPAM (Spamvertised) peuvent ne pas être hébergés sur nos serveurs. Cette disposition inclut, mais sans s'y limiter, le SPAM envoyé par fax, téléphone, courrier postal, e-mail, messagerie instantanée ou usenet / groupes de discussion. Aucune organisation ou entité répertoriée dans le ROKSO ne peut être hébergée sur nos serveurs. Tout compte qui entraîne la mise sur liste noire de notre espace IP sera immédiatement suspendu et / ou résilié.
                    </Text>
                </TermsList>
            </Section>

            {/* Informations de paiement Section */}
            <Section>
                <H4Title>Informations de paiement</H4Title>
                <TermsList>
                    <Text>
                        1. Vous acceptez de fournir un paiement approprié pour les services reçus de NexGen IPTV, avant la période pendant laquelle ces services sont fournis. Vous acceptez que jusqu'à et à moins que vous informiez NexGen IPTV de votre désir d'annuler tout ou partie des services reçus, ces services seront facturés sur une base récurrente.
                    </Text>
                    <Text>
                        2. Les annulations doivent être effectuées en ligne, vous pouvez annuler votre compte d'hébergement ou votre compte de domaine en nous contactant.
                    </Text>
                    <Text>
                        3. En tant que client de NexGen IPTV, il est de votre responsabilité de vous assurer que vos informations de paiement sont à jour et que toutes les factures sont payées à temps. Vous acceptez que jusqu'à et à moins que vous informiez NexGen IPTV de votre désir d'annuler tout ou partie des services reçus, ces services seront facturés sur une base récurrente, sauf indication contraire. NexGen IPTV se réserve le droit de facturer votre carte de crédit ou les informations de facturation enregistrées chez nous. NexGen IPTV offre une période de grâce de 14 jours entre le moment où la facture est générée et le moment où elle doit être payée. Toute facture en souffrance depuis 14 jours et non payée entraînera des frais de retard et / ou une suspension de compte jusqu'à ce que le solde du compte ait été payé en totalité. Les frais de retard sont appliqués en plus de tout ce qui est dû à NexGen IPTV pour les services rendus. L'accès au compte ne sera pas rétabli tant que le paiement n'aura pas été reçu.
                    </Text>
                    <Text>
                        4. Les renouvellements de domaine sont facturés et renouvelés 7 jours avant la date de renouvellement. Aucun remboursement ne peut être effectué une fois le domaine renouvelé. Tous les enregistrements et renouvellements de domaine sont définitifs.
                    </Text>
                    <Text>
                        5. Les comptes en souffrance depuis 30 jours seront automatiquement supprimés de nos serveurs. Si vous souhaitez activer un compte en souffrance depuis plus de 30 jours, des frais de 25,00 $ seront facturés pour restaurer votre compte à partir des sauvegardes (si disponibles). Tout prix spécial que vous aviez sur votre ancien compte ne sera pas transféré sur le nouveau compte, tous les nouveaux comptes paieront les prix actuels sur notre site Web.
                    </Text>
                    <Text>
                        6. Toute rétrofacturation contre NexGen IPTV entraînera des frais de rétrofacturation de 150 $.
                    </Text>
                </TermsList>
            </Section>

            {/* Sauvegardes et perte de données Section */}
            <Section>
                <H4Title>Sauvegardes et perte de données</H4Title>
                <TermsList>
                    <Text>
                        1. Votre utilisation de notre service de sauvegarde est à vos propres risques. Notre service de sauvegarde fonctionne plusieurs fois par semaine, écrase toutes nos sauvegardes précédentes et plusieurs sauvegardes sont conservées (quotidiennes, hebdomadaires, mensuelles, etc.). Ce service vous est fourni à titre gracieux. NexGen IPTV n'est pas responsable des fichiers et / ou des données résidant sur votre compte. Vous acceptez d'assumer l'entière responsabilité des fichiers et données transférés et de maintenir votre propre sauvegarde des fichiers et des données stockés sur les serveurs de NexGen IPTV. Dans certaines circonstances, nous avons découvert que certains sites Web ne généraient pas correctement les fichiers de sauvegarde sur les serveurs de NexGen IPTV. Cela est généralement le résultat d'autorisations de fichiers inhabituelles sur vos données. Lorsque cela se produit, NexGen IPTV ne sera pas responsable de vos sauvegardes. Nous recommandons vivement aux clients de télécharger leurs propres fichiers de sauvegarde au cas où cette situation se présenterait.
                    </Text>
                    <Text>
                        2. Les multiples sauvegardes à distance de NexGen IPTV sont un bonus gratuit que nous offrons à tous nos clients. Nous ne pouvons garantir l'intégrité de vos données. Bien que nous mettions tout en œuvre pour protéger vos données, nous ne donnons aucune garantie et ne serons pas tenus responsables en cas de problème avec l'intégrité de vos fichiers de sauvegarde.
                    </Text>
                </TermsList>
            </Section>

            {/* Annulations et remboursements Section */}
            <Section>
                <H4Title>Annulations et remboursements</H4Title>
                <TermsList>
                    <Text>
                        1. NexGen IPTV se réserve le droit d'annuler, de suspendre ou de restreindre autrement l'accès au compte à tout moment avec ou sans préavis. Tout abus de notre personnel sur quelque support ou format que ce soit entraînera la suspension ou la résiliation de vos services sans remboursement. Les clients peuvent annuler à tout moment en nous contactant. NexGen IPTV vous offre une garantie de remboursement initiale de 30 jours sur les plans semi-dédiés. Les serveurs dédiés ont une garantie de remboursement inconditionnelle de 7 jours.
                    </Text>
                    <Text>
                        2. La garantie de remboursement de 30 jours s'applique uniquement pendant vos 30 premiers jours de service avec nous. Il n'y a pas de remboursement sur les serveurs dédiés, les frais administratifs, les frais d'installation pour les logiciels personnalisés, les adresses IP dédiées ou les achats de noms de domaine. Seuls les comptes pour la première fois sont éligibles à notre garantie de remboursement. Par exemple, si vous avez déjà eu un compte chez nous, annulé et réinscrit, vous ne serez pas éligible à un remboursement ou si vous avez ouvert un deuxième compte chez nous. Après les 30 premiers jours (vos 30 premiers jours de service), aucun remboursement ne sera effectué. Si vous avez configuré un abonnement de paiement automatique via Paypal, celui-ci ne peut être annulé que par vous. Si vous n'annulez pas votre abonnement au paiement automatique, nous n'en serons pas responsables et vous n'aurez pas droit à un remboursement. Ceci est strictement sous le contrôle de notre client et nous ne serons pas responsables de vos abonnements. Les violations des conditions d'utilisation entraîneront la renonciation à la politique de remboursement.
                    </Text>
                </TermsList>
            </Section>

            {/* L'utilisation des ressources Section */}
            <Section>
                <H4Title>L'utilisation des ressources</H4Title>
                <TermsList>
                    <Text>Les utilisateurs ne peuvent pas:</Text>
                    <TermsList>
                        <li>Exécutez des processus côté serveur autonomes et sans assistance à tout moment sur le serveur. Cela inclut tous les démons, tels que IRCD.</li>
                        <li>Exécutez n'importe quel type d'araignée Web ou d'indexeur (y compris Google Cash / AdSpy) sur tous les plans d'hébergement.</li>
                        <li>Exécutez tout logiciel qui s'interface avec un réseau IRC (Internet Relay Chat).</li>
                        <li>Exécutez n'importe quelle application, tracker ou client bit torrent. Vous pouvez créer un lien vers des torrents légaux hors site, mais ne pouvez pas les héberger ou les stocker sur nos serveurs partagés.</li>
                        <li>Participez à toutes les activités de partage de fichiers / peer-to-peer.</li>
                        <li>Exécutez tous les serveurs de jeu tels que Counter-Strike, Half-Life, Battlefield1942, etc.</li>
                        <li>Exécutez les entrées cron avec des intervalles de moins de 5 minutes.</li>
                        <li>Exécutez toutes les requêtes MySQL de plus de 90 secondes. Les tables MySQL doivent être indexées de manière appropriée.</li>
                        <li>Connectez leur compte à une base de données distante. En d'autres termes, si vous utilisez l'un de nos plans d'hébergement, vous devez exécuter vos programmes à partir d'une base de données située sur nos serveurs et non à partir d'une base de données distante.</li>
                    </TermsList>
                </TermsList>
            </Section>

            {/* Utilisation de la bande passante Section */}
            <Section>
                <H4Title>Utilisation de la bande passante</H4Title>
                <TermsList>
                    <Text>
                        1. Vous bénéficiez d'une allocation mensuelle de bande passante. Cette allocation varie en fonction du pack d'hébergement que vous achetez. Si votre compte dépasse le montant alloué, nous nous réservons le droit de suspendre le compte jusqu'au début de la prochaine allocation, de suspendre le compte jusqu'à ce que plus de bande passante soit achetée moyennant des frais supplémentaires, de suspendre le compte jusqu'à ce que vous passiez à un niveau supérieur de package, résilier le compte et / ou vous facturer des frais supplémentaires pour les excédents. Le transfert non utilisé dans un mois ne peut pas être reporté au mois suivant.
                    </Text>
                    <Text>
                        2. NexGen IPTV n'activera pas de nouvelles commandes ou n'activera pas de nouveaux forfaits pour les clients qui ont un solde impayé avec NexGen IPTV. Pour qu'une nouvelle commande soit configurée ou qu'un nouveau forfait soit activé, vous devez avoir un solde de 0,00 $, sauf indication contraire de NexGen IPTV par écrit.
                    </Text>
                </TermsList>
            </Section>

            {/* Garantie de disponibilité Section */}
            <Section>
                <H4Title>Garantie de disponibilité</H4Title>
                <TermsList>
                    <Text>
                        Si votre serveur ou votre hébergement a un temps d'arrêt physique qui n'est pas dans le temps de disponibilité de 99,99%, vous pouvez recevoir un mois de crédit sur votre compte. L'approbation du crédit est à la discrétion de NexGen IPTV en fonction de la justification fournie. Les rapports des services de surveillance tiers ne peuvent pas être utilisés à des fins de justification en raison de divers facteurs, notamment la capacité du réseau du moniteur / la disponibilité du transit. Le temps de disponibilité du serveur est défini comme le temps de disponibilité signalé par le système d'exploitation et le serveur Web Apache, qui peut différer du temps de disponibilité signalé par d'autres services individuels. Pour demander un crédit, veuillez nous contacter avec justification. Toutes les demandes doivent être faites par écrit par courrier électronique.
                    </Text>
                </TermsList>
            </Section>

            {/* Revendeur: responsabilité du client Section */}
            <Section>
                <H4Title>Revendeur: responsabilité du client</H4Title>
                <TermsList>
                    <Text>
                        Les revendeurs sont responsables de soutenir leurs clients. NexGen IPTV ne fournit pas d'assistance aux clients de nos revendeurs. Si le client d'un revendeur nous contacte, nous nous réservons le droit de suspendre le compte client jusqu'à ce que le revendeur puisse assumer sa responsabilité envers son client. Toutes les demandes d'assistance doivent être effectuées par le revendeur au nom de ses clients pour des raisons de sécurité. Les revendeurs sont également responsables de tous les contenus stockés ou transmis sous leur compte revendeur et des actions de leurs clients. NexGen IPTV tiendra tout revendeur responsable de toute action de ses clients qui enfreint la loi ou les conditions de service.
                    </Text>
                </TermsList>
            </Section>

            {/* Changement de prix Section */}
            <Section>
                <H4Title>Changement de prix</H4Title>
                <TermsList>
                    <Text>
                        Le montant que vous payez pour l'hébergement n'augmentera jamais à partir de la date d'achat. Nous nous réservons le droit de modifier les prix indiqués sur NexGen IPTV et le droit d'augmenter la quantité de ressources allouées aux plans à tout moment.
                    </Text>
                </TermsList>
            </Section>

            {/* Coupons Section */}
            <Section>
                <H4Title>Coupons</H4Title>
                <TermsList>
                    <Text>
                        Les remises et les codes de réduction sont réservés aux nouveaux comptes * ou aux nouveaux clients * uniquement et ne peuvent pas être utilisés pour l'achat d'un enregistrement de domaine, sauf indication contraire. Si vous vous êtes inscrit en utilisant un domaine particulier, vous ne pouvez pas résilier votre abonnement pour ce domaine en utilisant un autre coupon à une date ultérieure. Tout compte trouvé en violation de ces politiques sera examiné par notre service commercial et les factures appropriées seront ajoutées au compte. L'abus de coupon ne sera pas toléré et peut entraîner la suspension ou la résiliation du compte.
                    </Text>
                </TermsList>
            </Section>

            {/* Indemnité Section */}
            <Section>
                <H4Title>Indemnité</H4Title>
                <TermsList>
                    <Text>
                        Le client accepte de défendre, d'indemniser, de sauvegarder et de dégager NexGen IPTV de toute demande, responsabilité, perte, coût et réclamation, y compris les honoraires d'avocat raisonnables réclamés contre NexGen IPTV, ses agents, ses clients, dirigeants et employés, qui peuvent découler ou résulter de tout service fourni ou exécuté ou convenu d'être exécuté ou de tout produit vendu par le client, ses agents, employés ou ayants droit. Le client accepte de défendre, d'indemniser et d'exonérer NexGen IPTV contre les responsabilités découlant de: (1) toute blessure à la personne ou à la propriété causée par tout produit vendu ou autrement distribué en relation avec NexGen IPTV; (2) tout matériel fourni par le client enfreignant ou prétendument enfreindre les droits de propriété d'un tiers.
                    </Text>
                </TermsList>
            </Section>

            {/* Arbitrage Section */}
            <Section>
                <H4Title>Arbitrage</H4Title>
                <TermsList>
                    <Text>
                        En utilisant les services de NexGen IPTV, vous acceptez de vous soumettre à un arbitrage exécutoire. En cas de litige ou de réclamation contre NexGen IPTV ou ses filiales, ces litiges seront traités par un arbitre au choix de NexGen IPTV. Un arbitre de l'American Arbitration Association ou du National Arbitration Forum sera sélectionné dans l'état du New Hampshire. Les arbitres seront des avocats ou des juges à la retraite et seront choisis conformément aux règles applicables. Toutes les décisions rendues par l'arbitre seront exécutoires et définitives. La décision de l'arbitre est définitive et lie toutes les parties. La loi fédérale sur l'arbitrage, et non une loi d'arbitrage d'un État, régit tous les arbitrages en vertu de cette clause d'arbitrage. Vous êtes également responsable de tous les coûts liés à un tel arbitrage.
                    </Text>
                </TermsList>
            </Section>

            {/* Avertissement Section */}
            <Section>
                <H4Title>Avertissement</H4Title>
                <TermsList>
                    <Text>
                        NexGen IPTV ne sera pas responsable des dommages que votre entreprise pourrait subir. NexGen IPTV n'offre aucune garantie d'aucune sorte, expresse ou implicite, pour les services que nous fournissons. NexGen IPTV décline toute garantie, qualité marchande ou aptitude à un usage particulier. Cela inclut la perte de données résultant de retards, d'absence de livraison, de mauvaise livraison et de toutes les interruptions de service causées par NexGen IPTV et ses employés.
                    </Text>
                </TermsList>
            </Section>

            {/* Divulgation aux forces de l'ordre Section */}
            <Section>
                <H4Title>Divulgation aux forces de l'ordre</H4Title>
                <TermsList>
                    <Text>
                        NexGen IPTV peut divulguer toute information relative à l'abonné aux organismes chargés de l'application de la loi sans autre consentement ni notification à l'abonné à la demande légale de ces organismes. Nous coopérerons pleinement avec les organismes d'application de la loi.
                    </Text>
                </TermsList>
            </Section>

            {/* Modifications du TOS Section */}
            <Section>
                <H4Title>Modifications du TOS</H4Title>
                <TermsList>
                    <Text>
                        NexGen IPTV se réserve le droit de réviser ses politiques à tout moment sans préavis.
                    </Text>
                </TermsList>
            </Section>
        </TosPageContainer>
    );
};

export default TosPage;
