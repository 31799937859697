import styled from "styled-components";

const QnAContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 0px;
	width: 100%;
	@media (max-width: 768px) {
		padding: 0px 15px;
		margin-top: 200px;
	}
`;

const QnAContent = styled.div`
	width: 800px;
	margin-top: 52px;

	@media (max-width: 768px) {
		width: 100%;
		margin-top: 52px;
	}
`;

const QnATitle = styled.h1`
	color: white;
	font-size: 42px;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 27px;
	}
`;

const QnASection = styled.div`
	margin-top: 65px;

	@media (max-width: 768px) {
		margin-top: 30px;
	}
`;

const QnASubSection = styled.div``;

const QnASubTitle = styled.div`
	color: white;
	background-color: #303030;
	padding: 28px 0px;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 24px;
	position: relative;
	cursor: pointer;
	margin-bottom: 10px;

	@media (max-width: 768px) {
		padding: 20px 15px;
		font-size: 16px;
	}
`;

const QnASubDesc = styled.div`
	color: white;
	background-color: #303030;
	font-size: 21px;
	line-height: 1.6;
	border-top: 1px solid black;
	position: absolute;
	top: 0;
	left: 0;
	height: 0px;
	overflow: hidden;
	box-sizing: border-box;
	z-index: -1;
	padding-top: 30px;

	&.active {
		position: static;
		height: 240px;
		margin-top: 25px;
		transition: 0.3s;
	}

	@media (max-width: 768px) {
		margin-top: 20px;
		padding: 0;
		padding-top: 20px;
		font-size: 15px;

		&.active {
			height: 240px;
		}
	}
`;

const handleQnATitle = (event) => {
	event.target.children[0].classList.toggle("active");
};

const QnA = () => {
	return (
		<QnAContainer className="efwgr">
			<QnAContent>
				<QnATitle>Questions Fréquemment Posées</QnATitle>
				<QnASection>
					<QnASubSection>
						<QnASubTitle onClick={handleQnATitle}>
							Qu'est-ce que NexGen IPTV ?
							<QnASubDesc>
								Un fournisseur de service IPTV fiable et puissant offrant une
								expérience de visionnage haute qualité. Notre service IPTV
								comprend des chaînes de télévision en direct, des programmes à
								la demande, des enregistrements de DVR, une intégration de
								services tiers tels que Netflix et Amazon Prime Video, une
								interface utilisateur conviviale et des mises à jour régulières
								de contenu
								<br></br>
								<br></br>
								{/* tttt */}
							</QnASubDesc>
						</QnASubTitle>

						<QnASubTitle onClick={handleQnATitle}>
							Comment puis-je accéder au service IPTV sur mon téléviseur ?
							<QnASubDesc>
								Vous pouvez accéder au service IPTV sur votre téléviseur en
								utilisant une application IPTV compatible avec votre téléviseur
								intelligent ou en utilisant un boîtier IPTV qui se connecte à
								votre téléviseur, ou bien votre Smartphone, Tablet,PC ...
								<br></br>
								<br></br>
								{/* tttt */}
							</QnASubDesc>
						</QnASubTitle>

						<QnASubTitle onClick={handleQnATitle}>
							Comment puis-je trouver un service IPTV fiable et de qualité ?
							<QnASubDesc>
								Pour trouver un service IPTV fiable et de qualité, vous pouvez
								consulter nos offres et nos prix compétitifs par rapport aux
								autres fournisseurs de services IPTV.
								<br></br>
								<br></br>
							</QnASubDesc>
						</QnASubTitle>
					</QnASubSection>
				</QnASection>
			</QnAContent>
		</QnAContainer>
	);
};

export default QnA;
