import styled from "styled-components";
import { useHistory } from 'react-router-dom';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  border-top: 1px solid rgb(25, 25, 25);
  width: 100%;
  position: relative;
  z-index: 100;

  @media (max-width: 768px) {
    padding: 20px 20px;
    padding-bottom: 30px;
  }
`;

const FooterContent = styled.div``;
const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterLink = styled.a`
  color: white;
  font-size: 18px;
  margin: 10px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterDescContainer = styled.div`
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const FooterDescRights = styled.h5`
  color: white;
  font-size: 10px;
  text-align: center;
`;

const Footer = () => {
	const history = useHistory();

	const OnClickFooterLinkHandler = (routeValue) => {
		console.log(routeValue);
		history.push(routeValue);
	}


  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinkContainer>
          <FooterLink onClick={() => OnClickFooterLinkHandler("terms-and-conditions")}>Termes et Conditions</FooterLink>
          <FooterLink onClick={() => OnClickFooterLinkHandler("privacy-policy")}>Politique de Confidentialité</FooterLink>
        </FooterLinkContainer>
        <FooterDescContainer>
          <FooterDescRights> NexGen IPTV © 2024. ALL RIGHTS RESERVED.</FooterDescRights>
        </FooterDescContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
