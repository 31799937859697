import React from "react";

function PrincingTable(props) {

	const handleClick = (url) => {
        window.location.href = url;
    };

	return (
		<div>
			<section class="pricing-plans" id="#pricing-plans-section"> 
				<div class="pricing-card basic">
					<div class="heading">
						<h4>1 Month Subscription</h4>
						<p></p>
					</div>
					<p class="price">
					15.99 €
						<sub>/ Mois</sub>
					</p>
					<ul class="features">
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+10000 Live </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+40000 </strong> VOD
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>TV Shows & </strong>Movies
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>4K FHD HD </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong> Fast & Stable </strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>Free Updates</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>99% Uptime Uptime</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>24/7</strong> support
						</li>
					</ul>
					<button class="cta-btn" onClick={() => handleClick("https://href.li/?https://payallinonesolutions.pro/checkout-5/step/1-month-subscription/")}>Sélectionner</button>
				</div>
				<div class="pricing-card standard">
					<div class="heading">
						<h4>3 Months Subscription</h4>
						<p></p>
					</div>
					<p class="price">
					25.99 €
						<sub>/ 3 Mois</sub>
					</p>
					<ul class="features">
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+10000 Live </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+40000 </strong> VOD
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>TV Shows & </strong>Movies
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>4K FHD HD </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong> Fast & Stable </strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>Free Updates</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>99% Uptime Uptime</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>24/7</strong> support
						</li>
					</ul>
					<button class="cta-btn" onClick={() => handleClick("https://href.li/?https://payallinonesolutions.pro/checkout-4/step/basic-plan/")}>Sélectionner</button>
				</div>
				<div class="pricing-card standard-pro">
					<div class="heading">
						<h4>6 Months Subscription</h4>
						<p></p>
					</div>
					<p class="price">
					35.99 €
						<sub>/ 6 Mois</sub>
					</p>
					<ul class="features">
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+10000 Live </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+40000 </strong> VOD
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>TV Shows & </strong>Movies
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>4K FHD HD </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong> Fast & Stable </strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>Free Updates</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>99% Uptime Uptime</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>24/7</strong> support
						</li>
					</ul>
					<button class="cta-btn" onClick={() => handleClick("https://href.li/?https://payallinonesolutions.pro/checkout-3/step/standard-plan//")}>Sélectionner</button>
				</div>
				<div class="pricing-card premium">
					<div class="heading">
						<h4>1 Year Subscription</h4>
						<p></p>
					</div>
					<p class="price">
					49.99 €
						<sub>/ an</sub>
					</p>
					<ul class="features">
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+10000 Live </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+40000 </strong> VOD
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>TV Shows & </strong>Movies
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>4K FHD HD </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong> Fast & Stable </strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>Free Updates</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>99% Uptime Uptime</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>24/7</strong> support
						</li>
					</ul>
					<button class="cta-btn" onClick={() => handleClick("https://href.li/?https://payallinonesolutions.pro/checkout-2/step/premium-plan/")}>Sélectionner</button>
				</div>
				<div class="pricing-card">
					<div class="heading">
						<h4>2 Years Subscription</h4>
						<p></p>
					</div>
					<p class="price">
					85.99 €
						<sub>/ an</sub>
					</p>
					<ul class="features">
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+10000 Live </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>+40000 </strong> VOD
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>TV Shows & </strong>Movies
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>4K FHD HD </strong> Channels
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong> Fast & Stable </strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>Free Updates</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>99% Uptime Uptime</strong>
						</li>
						<li>
							<i class="fa-solid fa-check"></i>
							<strong>24/7</strong> support
						</li>
					</ul>
					<button class="cta-btn" style={{ color: 'black' }}  onClick={() => handleClick("https://href.li/?https://payallinonesolutions.pro/checkout/step/enterprise-plan/")}>Sélectionner</button>
				</div>
			
			</section>
		</div>
	);
}

export default PrincingTable;
